import { Text } from '@app/components'
import { toCurrency } from '@app/utils'
import { Menu } from 'antd'
import { useContext, useEffect } from 'react'

import { BalanceContext } from '../fortress/BalanceContext'
import { ITEMS } from './SidebarMenuItems'

export const Sidebar = ({
  onClick,
  router,
  backgroundColor,
}: {
  onClick: (route: string) => void
  router: any
  backgroundColor: any
}) => {
  const { balance, fetchAccounts } = useContext(BalanceContext)

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  const menuItems = ITEMS.map((item) => ({
    icon: item.icon,
    key: item.key,
    label:
      item.key === '3' ? (
        <>
          <Text style={{ color: '#d7d7d7', marginLeft: '15px', marginTop: '10px' }}>
            {balance ? toCurrency(balance, true) : '$0.00'}
          </Text>
        </>
      ) : (
        item.label
      ),
    onClick: item.key === '3' ? fetchAccounts : () => onClick(item.route),
  }))

  return (
    <Menu
      selectedKeys={[router.route.slice(1)]}
      theme={'dark'}
      defaultSelectedKeys={[router.route.slice(1)]}
      items={menuItems}
      style={{ ...backgroundColor }}
    />
  )
}
