import {
  CSTContract,
  DaiContract,
  HexContract,
  IncContract,
  PhlpContract,
  PlsxContract,
  TestMainWaitContract,
  TestWaitContract,
  USDCContract,
  USDTContract,
  WaitContract,
  wPlsContract,
} from 'libs/data/contracts'
import { useAccount, useBalance, useChainId } from 'wagmi'

import useRead, { ContractRead } from './useRead'

const useCSTBalance = (writing) => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: CSTContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export default useCSTBalance

export const usePlsBalance = () => {
  const { address } = useAccount()

  // useBalance hook to get the balance of the connected account
  const { data, isError, isLoading } = useBalance({
    address,
  })

  // You can add any additional logic or validations you need here
  // For example, checking if there's an error or if the data is still loading

  return { value: data?.formatted, isError, isLoading }
}

export const useHexBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: HexContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const usewPlsBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: wPlsContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const usePlsxBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: PlsxContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const useIncBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: IncContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const usePhlpBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: PhlpContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const useWaitBalance = (writing): any => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  const waitAddress =
    process.env.NEXT_PUBLIC_ENV == 'development' && chainId == 943
      ? TestWaitContract.address
      : chainId == parseInt(process.env.NEXT_PUBLIC_ETH_CHAIN) && process.env.NEXT_PUBLIC_ENV == 'development'
        ? TestMainWaitContract.address
        : process.env.NEXT_PUBLIC_ENV != 'development'
          ? WaitContract.address
          : WaitContract.address

  return useRead({
    contract: waitAddress as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    validate,
    writing,
  })
}

export const useETHUSDCBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: USDCContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_ETH_CHAIN),
    validate,
    writing,
  })
}

export const useETHUSDTBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: USDTContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_ETH_CHAIN),
    validate,
    writing,
  })
}

export const useETHDAIBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: DaiContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_ETH_CHAIN),
    validate,
    writing,
  })
}

export const usePlsUSDCBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: USDCContract.bridgeAddress as `0x${string}`,
    abi: USDCContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const usePlsUSDTBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: USDTContract.bridgeAddress as `0x${string}`,
    abi: USDTContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const usePlsDAIBalance = (writing): ContractRead<bigint> => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = useAccount()
  const chainId = useChainId()
  return useRead({
    contract: DaiContract.bridgeAddress as `0x${string}`,
    abi: DaiContract.abi,
    functionName: 'balanceOf',
    args: [account.address],
    enabled: chainId === parseInt(process.env.NEXT_PUBLIC_PULSECHAIN),
    validate,
    writing,
  })
}

export const useBuyBalance = (writing) => {
  const validate = (value: unknown): value is bigint => typeof value === 'bigint'
  const account = process.env.NEXT_PUBLIC_SWAP_ADDRESS
  return useRead({
    contract: CSTContract.address as `0x${string}`,
    abi: CSTContract.abi,
    functionName: 'balanceOf',
    args: [account],
    enabled: true,
    validate,
    writing,
  })
}
