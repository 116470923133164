import { formatWalletAddress, toCurrency } from '@app/utils'
import axios from 'axios'

export const createTweet = async (auth, body) => {
  const authorization = await auth.currentUser.getIdToken()
  const headers = {
    Authorization: authorization,
  }
  let data
  switch (body.type) {
    case 'mint': {
      data = {
        // This has to be divided cause it's coming directly from the contract
        text: `🪙 🪙 🪙 ${formatWalletAddress(body.address)} has just minted $${toCurrency(
          body.amount,
          false,
        )} $CST on #PulseChain. Mint yours at app.0xcoast.com/mint-and-burn`,
      }
      break
    }
    case 'burn': {
      data = {
        // This doesn't need to be diviced cause it's coming from the user's input
        text: `🔥 🔥 🔥 ${formatWalletAddress(body.address)} has just burned $${toCurrency(body.amount, false)} ${
          body.token
        } on #PulseChain. Mint yours at app.0xcoast.com/mint-and-burn `,
      }
      break
    }
    case 'swapb': {
      data = {
        text: `🔀 🔀 🔀 ${formatWalletAddress(body.address)} just sold $${
          body.amount
        } of $CST The best place to trade $CST on #PulseChain is app.0xcoast.com/swap`,
      }
      break
    }
    case 'swaps': {
      data = {
        text: `🔀 🔀 🔀 ${formatWalletAddress(body.address)} just bought ${toCurrency(body.amount, false)} $${
          body.token
        } The best place to 'Buy' $${body.token} on #PulseChain is app.0xcoast.com/buy-and-sell`,
      }
      break
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Error tweeting, wrong type entered')
    }
  }

  try {
    const response: any = await axios.post('/api/twitter/create', data, { headers })
    if (response.data.success) {
      // eslint-disable-next-line no-console
      // console.log('Tweeted successfully:', response.data)
    } else {
      // eslint-disable-next-line no-console
      console.error('Error tweeting', response.error)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('There was an error:', error)
  }
}
